import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';

export interface Resource {
  guid: string;
  test_id: number;
  test_name: string;
  connector: string;
  service: string;
  last_run: string;
  status: string;
  framework: string;
  resource_name: string;
}

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  @Input() selectedFrameworks: any[] = [];
  @Input() selectedConnectors: any[] = [];
  @Input() selectedAccounts: any[] = [];
  @Input() selectedResources: any[] = [];
  @Input() selectedServices: any[] = [];
  @Input() include_ignored_test: boolean;
  @Input() activeStatus: string;
  @Output() setStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickResourceTitle: EventEmitter<string> = new EventEmitter<string>();
  @Output() csvDataEmitter = new EventEmitter<string>();
  @Output() resourcesListStatus = new EventEmitter<boolean>();
  @Output() setStatuses = new EventEmitter<any>();

  totalLength: any = 1;
  page = 1;
  pageSize = 10;
  OrganisationID: string | null;
  orgGuidSubscription: any;
  loading: boolean = false;
  searchText: string = '';
  @Input() testId = '';
  pageSizes = [
    { value: 10, label: '10 / page' },
    { value: 25, label: '25 / page' },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' }
  ];
  resourceList: Resource[];
  resourceListWithoutPagination: any[];
  filters: any = {};
  selectedAdditionalStatus: any;

  constructor(private orgService: OrganisationService, private documentsService: DocumentsService) {}

  // ngOnChanges lifecycle hook
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedFrameworks']) {
      this.filters['frameworks'] = this.selectedFrameworks;
    }

    if (changes['selectedConnectors']) {
      this.filters['connectors'] = this.selectedConnectors;
    }

    if (changes['selectedAccounts']) {
      this.filters['accounts'] = this.selectedAccounts;
    }

    // if (changes['selectedResources']) {
    //   this.filters['resources'] = this.selectedResources;
    // }

    if (changes['selectedServices']) {
      this.filters['services'] = this.selectedServices;
    }

    if (this.OrganisationID && !this.testId) {
      this.page = 1;
      this.getTestList();
      this.getStatusCount();
      this.checkresourcesListStatus();
    }

    if (
      changes['activeStatus']?.currentValue !== changes['activeStatus']?.previousValue &&
      this.testId &&
      this.OrganisationID
    ) {
      this.getDetailResouceList();
    }
  }

  checkresourcesListStatus() {
    this.resourcesListStatus.emit(this.resourceList && this.resourceList.length > 0);
  }
  async exportTableData(): Promise<void> {
    try {
      await this.getresourcesListWithoutPagination();
      const csvData = this.generateCSV();
      if (csvData) {
        this.csvDataEmitter.emit(csvData);
      } else {
        console.error('CSV data is empty.');
      }
    } catch (error) {
      console.error('Error fetching test list or generating CSV:', error);
    }
  }
  getDate(date: string) {
    let newDate = date.split(' ')[0] + ' ' + date.split(' ')[1];
    return newDate;
  }
  generateCSV(): string {
    if (!this.resourceListWithoutPagination || this.resourceListWithoutPagination.length === 0) {
      return '';
    }
    const headers = ['RESOURCE NAME', 'CONNECTOR', 'SERVICE', 'STATUS', 'FRAMEWORK'];
    const allStatuses = this.resourceListWithoutPagination
      .map(resource => resource.status || [])
      .reduce((acc, statuses) => acc.concat(statuses), [] as string[]);
    let csvContent = headers.join(',') + '\r\n';
    this.resourceListWithoutPagination.forEach((resource: any) => {
      const compliant_count = `${this.toTitleCase('compliant')} (${resource.compliant_count})`;
      const warning_count = `${this.toTitleCase('warning')} (${resource.warning_count})`;
      const danger_count = `${this.toTitleCase('danger')} (${resource.danger_count})`;
      const ignored_count = `${this.toTitleCase('ignored')} (${resource.ignored_count})`;
      const not_started_count = `${this.toTitleCase('not started')} (${resource.not_started_count})`;
      let statuses: string[] = [];
      if (resource.compliant_count > 0) {
        statuses.push(`${compliant_count}`);
      }
      if (resource.warning_count > 0) {
        statuses.push(`${warning_count}`);
      }
      if (resource.danger_count > 0) {
        statuses.push(`${danger_count}`);
      }
      if (this.include_ignored_test && resource.ignored_count > 0) {
        statuses.push(`${ignored_count}`);
      }
      if (resource.not_started_count > 0) {
        statuses.push(`${not_started_count}`);
      }
      const formattedStatuses = statuses.join(',');
      const formattedFrameworks = resource.framework ? resource.framework.join(', ') : '';
      const values = [
        `"${resource.resource_name}"`,
        `"${resource.connector}"`,
        `"${resource.service}"`,
        `"${formattedStatuses}"`,
        `"${formattedFrameworks}"`
      ];

      csvContent += values.join(',') + '\r\n';
    });

    return csvContent;
  }
  toTitleCase(text: string): string {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  getresourcesListWithoutPagination(): Promise<void> {
    const queryString = this.objectToQueryString(this.filters);

    return new Promise((resolve, reject) => {
      this.documentsService
        .getTestListWithoutPagination(
          this.OrganisationID,
          queryString,
          this.include_ignored_test,
          this.searchText,
          this.activeStatus,
          'resources'
        )
        .subscribe(
          res => {
            this.resourceListWithoutPagination = res.results;
            resolve(); // Resolve the promise when data is set
          },
          error => {
            console.error('Error fetching test list:', error);
            reject(error); // Reject the promise if there is an error
          }
        );
    });
  }
  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (this.OrganisationID && !this.testId) {
      this.getTestList();
      this.getStatusCount();
    } else {
      this.getDetailResouceList();
    }
  }

  getTestList() {
    const queryString = this.objectToQueryString(this.filters);
    this.loading = true;
    this.documentsService
      .getTestList(
        this.OrganisationID,
        this.page,
        this.pageSize,
        queryString,
        this.include_ignored_test,
        this.searchText,
        this.activeStatus,
        'resources'
      )
      .subscribe(
        res => {
          this.totalLength = res.count;
          this.resourceList = res.results;
          this.loading = false;
          this.checkresourcesListStatus();
        },
        error => {
          this.loading = false;
          console.error('Error fetching test list:', error);
        }
      );
  }
  getDetailResouceList() {
    this.loading = true;
    this.documentsService
      .getCloudTestResource(
        this.OrganisationID,
        this.testId,
        this.activeStatus,
        this.page,
        this.pageSize,
        this.searchText
      )
      .subscribe(res => {
        this.totalLength = res.count;
        this.resourceList = res.results;
        this.loading = false;
        res.status_counts['all'] = res.total_counts;
        this.setStatuses.emit(res.status_counts);
      });
  }

  getStatusCount() {
    const queryString = this.objectToQueryString(this.filters);
    this.documentsService
      .getStatusCount(
        this.OrganisationID,
        queryString,
        this.include_ignored_test,
        this.searchText,
        this.activeStatus,
        'resources'
      )
      .subscribe(res => {
        this.setStatus.emit(res);
      });
  }

  objectToQueryString(obj: Record<string, unknown>): string {
    return Object.entries(obj)
      .filter(([_, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map(item => encodeURIComponent(String(item)));
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(encodedValues))}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
  }

  getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'compliant':
        return 'compliant';
      case 'danger':
        return 'danger';
      case 'not_started':
        return 'not-started';
      case 'warning':
        return 'warning';
      case 'ignored':
        return 'ignored';
      default:
        return '';
    }
  }

  getFrameworkClass(framework: string): string {
    switch (framework.toLowerCase()) {
      case 'hipaa':
        return 'hipaa';
      case 'soc2':
        return 'soc2';
      case 'iso27001':
        return 'iso27001';
      case 'gdpr':
        return 'gdpr';
      default:
        return '';
    }
  }

  changePageSize(pageSize: any) {
    this.pageSize = pageSize;
    if (this.testId) {
      this.getDetailResouceList();
    } else {
      this.getTestList();
    }
  }

  goToPageChange(value: number = 1) {
    this.page = value;
    if (value > Math.ceil(this.totalLength / this.pageSize)) {
      this.page = Math.ceil(this.totalLength / this.pageSize);
    }
    if (this.testId) {
      this.getDetailResouceList();
    } else {
      this.getTestList();
    }
  }

  pageChange(value: number = 1) {
    this.page = value;
    if (this.testId) {
      this.getDetailResouceList();
    } else {
      this.getTestList();
    }
  }

  searchByResource() {
    if (this.OrganisationID) {
      if (this.testId) {
        this.getDetailResouceList();
      } else {
        this.getTestList();
        this.getStatusCount();
      }
    }
  }

  getStatusKey(arr: any) {
    return Object.keys(arr).sort((a, b) => (a === this.activeStatus ? -1 : b === this.activeStatus ? 1 : 0));
  }
  onClickResource(resourceTitle: string) {
    this.onClickResourceTitle.emit(resourceTitle);
  }

  onClickAdditionalStatus(status: any) {
    this.selectedAdditionalStatus = status;
  }
}
